import photoAlbumsCreator from '../utils/PhotoAlbumsCreator';

const ALBUMS = [
  {
    title: 'People',
    photoElements: [
      {
        src: 'https://imgur.com/tXZXxPB.jpg',
        caption: 'Sumin at Gyeongbokgung Palace',
        tw: 3480,
        th: 4640,
      },
      {
        src: 'https://imgur.com/SZ0O3pw.jpg',
        caption: 'Sumin & Cacti',
        tw: 4640,
        th: 3480,
      },
      {
        src: 'https://imgur.com/eNOel1Z.jpg',
        caption: 'Me Times Three',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/Uib5MK1.jpg',
        caption: 'Tyler Kayaking',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/ngTlx0p.jpg',
        caption: 'Niki & Selfie',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/NgjwMRI.jpg',
        caption: 'Jon at Ontario Beach',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/MM6JHgq.jpg',
        caption: 'Niki & Connor',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/wRjcsvG.jpg',
        caption: 'Lounging 2x Selfie',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/FYMymGG.jpg',
        caption: 'Colin & Connie',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/gv4M92Q.jpg',
        caption: 'Colin',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/BfjxCZm.jpg',
        caption: 'Darius',
        tw: 3168,
        th: 4752,
      },
      {
        src: 'https://imgur.com/Nbrz80v.jpg',
        caption: 'Frozen Selfie',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/PXozeQq.jpg',
        caption: 'Ontario Beach Squats',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/RhUt0X5.jpg',
        caption: 'Deryck Dab',
        tw: 3168,
        th: 4752,
      },
      {
        src: 'https://imgur.com/rEcbpNn.jpg',
        caption: 'Sumin',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/RaXZZQj.jpg',
        caption: 'Maggie Jump',
        tw: 4752,
        th: 3168,
      },
    ],
  },
  {
    title: 'Nature',
    photoElements: [
      {
        src: 'https://imgur.com/erXDz0M.jpg',
        caption: 'Lilly',
        tw: 4128,
        th: 2322,
      },
      {
        src: 'https://imgur.com/0WQwgrw.jpg',
        caption: 'Bonsai',
        tw: 4128,
        th: 2322,
      },
      {
        src: 'https://imgur.com/ZTzdozs.jpg',
        caption: 'Cacti',
        tw: 1500,
        th: 1000,
      },
      {
        src: 'https://imgur.com/hVZMyg2.jpg',
        caption: 'Fall Rail',
        tw: 4128,
        th: 2322,
      },
      {
        src: 'https://imgur.com/mJhEvRv.jpg',
        caption: 'Trees at Gyeongbokgung Palace',
        tw: 4640,
        th: 3480,
      },
      {
        src: 'https://imgur.com/6Oc4fmU.jpg',
        caption: 'Civic at Letchworth State Park',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/wrUaPnk.jpg',
        caption: 'Car and Stars',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/hoW6QHy.jpg',
        caption: 'Letchworth State Park',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/Yrj1vWp.jpg',
        caption: 'California Cliffs',
        tw: 4032,
        th: 3024,
      },
      {
        src: 'https://imgur.com/53a3z58.jpg',
        caption: 'Koi',
        tw: 1500,
        th: 1000,
      },
      {
        src: 'https://imgur.com/UnaaDm1.jpg',
        caption: 'Cacti',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/UQw4Z9t.jpg',
        caption: 'Ivy Fountain',
        tw: 3168,
        th: 4752,
      },
      {
        src: 'https://imgur.com/OvOoKNk.jpg',
        caption: 'Branch',
        tw: 3168,
        th: 4752,
      },
      {
        src: 'https://imgur.com/u8Uj99T.jpg',
        caption: 'Frozen Leaves',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/Eud0yPU.jpg',
        caption: 'Frozen Forest',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/pAHB5ZV.jpg',
        caption: 'Snowy Road',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/gQ4ZqKe.jpg',
        caption: 'Snowy Home',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/U89tPjk.jpg',
        caption: 'Petals',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/wJypq6D.jpg',
        caption: 'Petals',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/NCwslIa.jpg',
        caption: 'Niagara Falls Rainbow',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/FnHfp2b.jpg',
        caption: 'Niagara Falls',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/fwD71xp.jpg',
        caption: 'Niagara Falls',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/slDIN5b.jpg',
        caption: 'Niagara Falls',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/MziRvBi.jpg',
        caption: 'Letchworth State Park',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/usdV1zp.jpg',
        caption: 'Drip Drop',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/4S7DZCv.jpg',
        caption: 'Syracuse Stars',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/jRNf1z7.jpg',
        caption: 'Bell Flower',
        tw: 4752,
        th: 3168,
      },
    ],
  },
  {
    title: 'Urban',
    photoElements: [
      {
        src: 'https://imgur.com/BYbqo2e.jpg',
        caption: 'RIT Sunset',
        tw: 3840,
        th: 2160,
      },
      {
        src: 'https://imgur.com/ZDcE32r.jpg',
        caption: 'Buffalo Botanical Gardens',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/7C4so6W.jpg',
        caption: 'Rochester Street Art - Badminton',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/gogo31G.jpg',
        caption: 'Rochester Street Art - Whale²',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/B3299yK.jpg',
        caption: 'Rochester Street Art - Paint Machine',
        tw: 4491,
        th: 2994,
      },
      {
        src: 'https://imgur.com/EDSy8CR.jpg',
        caption: 'Rochester Street Art - Abstract',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/wSBmEji.jpg',
        caption: 'Rochester Street Art - Abstract',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/YfJdkKV.jpg',
        caption: 'Rochester Street Art - Til We Meet Again',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/22CkA2I.jpg',
        caption: 'Rochester Street Art - Hawk',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/jZ2ZR9h.jpg',
        caption: 'Rochester Street Art - Human',
        tw: 3168,
        th: 4752,
      },
      {
        src: 'https://imgur.com/6cvnIZI.jpg',
        caption: 'Tile Art',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/bpGe2lB.jpg',
        caption: 'Buffalo - Cargill Pool Elevator',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/1JtQmrz.jpg',
        caption: 'Buffalo - Cargill Pool Elevator',
        tw: 4752,
        th: 3168,
      },
      {
        src: 'https://imgur.com/Dz5Dt0J.jpg',
        caption: 'Downtown Toronto',
        tw: 4752,
        th: 3168,
      },
    ],
  },
];

export default photoAlbumsCreator(ALBUMS);
